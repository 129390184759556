import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import bootstrap from "../components/bootstrap.module.css"
import FormatDate from "../utils/format-date.js"
import { autop } from '@wordpress/autop';
import { FacebookProvider, Comments  } from 'react-facebook';
import SEO from "../components/seo"
import Img from 'gatsby-image';
import Share from "../components/share"
import Breadcrumbs from "../components/breadcrumbs"


export default function Event( { pageContext, data } ) {

  const page = pageContext.data
  const seo = pageContext.seo
  const row = data.mysqlEvent

  return (
    <Layout>
        <SEO title={ seo && seo.title ? seo.title : page.post_title } canonical={ seo && seo.canonical ? seo.canonical : 'https://fintek.pl/wydarzenie/' + page.post_name + '/' } description={seo ? seo.description : row.post_excerpt} robots={[(seo && seo.is_robots_noindex?'noindex':'index'),(seo && seo.is_robots_nofollow?'nofollow':'follow')].join(', ')} image={page.mysqlImage ? 'https://fintek.pl' + page.mysqlImage.publicURL : null} />  

        <Breadcrumbs post={page} type={`event`} />

        <h1 className={bootstrap.mb3}>{ page.post_title }</h1>

        <Share url={"https://fintek.pl/"+page.post_name+'/'} text={page.post_title} />

        <div className={bootstrap.small + ' meta ' + bootstrap.mb2}>
          <div>Opublikowano {FormatDate(row.post_date)}</div>
          <div>Data wydarzenia <strong>{FormatDate(row.date, false)}</strong>, miejsce: {row.place || '-'}</div>
        </div>

        <p className={['font-serif-bold',bootstrap.my2,'post-excerpt'].join(' ')}><strong>{row.post_excerpt}</strong></p>

        {row.mysqlImage ? <Img fluid={row.mysqlImage.childImageSharp.fluid} className={bootstrap.my4} /> : ''}

        
        <div dangerouslySetInnerHTML={{ __html: autop(row.post_content) }} className={'post-content'} />
        
        <FacebookProvider appId="1317447385416669" language="pl_PL">
          <Comments language="pl_PL" href={"https://fintek.pl/"+page.post_name+'/'} width="100%" />
        </FacebookProvider>
    </Layout>
  )
  
}

export const query = graphql`
query EventQuery($slug: String, $id: Int) {
  mysqlEvent: mysqlEvent(post_name: {eq: $slug}) {
    id
    post_content
    post_date
    post_excerpt
    date
    city
    mysqlImage {
	  childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }    
  }

  seo: mysqlIndexable(object_type: {eq: "post"}, object_id: {eq: $id}) {
    title
    description
    object_id
    object_type
    permalink
    breadcrumb_title
    canonical
    is_robots_noindex
    is_robots_nofollow
  }

}
`
